<template>
  <div class="hello">
    <head-menu></head-menu>
    <div class="reset-pwd content">
      <div class="title">{{ $t('header.a5') }}</div>
      <ul class="input-form">
        <li>
          <div>{{ $t('cp.a1') }}：</div>
          <div>
            <el-input v-model="pwd" style="width: 282px;" size="small" type="password"></el-input>
          </div>
        </li>
        <li>
          <div>{{ $t('cp.a2') }}：</div>
          <div>
            <el-input v-model="new_pwd" style="width: 282px;" size="small" type="password"></el-input>
          </div>
        </li>
        <li>
          <div>{{ $t('cp.a3') }}：</div>
          <div>
            <el-input v-model="new_pwd2" style="width: 282px;" size="small" type="password"></el-input>
          </div>
        </li>
      </ul>
      <!-- button -->
      <div class="flex mar30 next-btn">
        <el-button size="large" @click="back">{{ $t('common.a7') }}</el-button>
        <el-button size="large" type="primary" @click="confirm">{{ $t('common.a4') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import headMenu from '../assets/commonComp/header'

export default {
  components: {
    headMenu
  },
  data() {
    return {
      pwd: '',
      new_pwd: '',
      new_pwd2: '',
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
      //sessionStorage.clear()
    },
    confirm() {
      if (!this.pwd) {
        return this.$message({
          type: 'warning',
          message: this.$t('message.a1')
        })
      } else if (!this.new_pwd) {
        return this.$message({
          type: 'warning',
          message: this.$t('message.a2')
        })
      } else if (this.new_pwd !== this.new_pwd2) {
        return this.$message({
          type: 'warning',
          message: this.$t('message.a3')
        })
      } else if (this.pwd != sessionStorage.getItem('pwd')) {
        return this.$message({
          type: 'warning',
          message: this.$t('message.a1-1')
        })
      } else if (this.checkPWD() === false) {
        return this.$message({
          type: 'warning',
          message: this.$t('message.a20')
        })
      }
      this.postRequest('index/change_pwd', {
        uid: sessionStorage.getItem('id'),
        pwd: this.pwd,
        new_pwd: this.new_pwd,
      }, res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.a4')
          })
          sessionStorage.removeItem('id');
          sessionStorage.removeItem('pwd');
          this.$router.push('/')
        }
      })
    },
    checkPWD() {
      if (/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*.+-])[\da-zA-Z~!@#$%^&*.+-]{7,}$/.test(this.new_pwd) == false) {
        return false
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (sessionStorage.getItem('pwd') == '000000' && to.path != '/' && to.path != '') {
      console.log(to, sessionStorage.getItem('pwd') == '000000' && (to.path != '/' || to.path != ''))
      return;
    } else {
      console.log(sessionStorage.getItem('pwd') == '000000' && (to.path != '/' || to.path != ''))
      next()
    }
  }
}
</script>

<style>
.reset-pwd .el-input__inner {
  font-size: 30px;
  color: black;
  border: 1px solid #999999;
  border-radius: 10px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: #F5F7FA;
}

.title {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
  padding: 35px 0 70px;
}

.input-form li {
  display: flex;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.input-form li div {
  width: 50%;
}

.input-form li div:first-child {
  text-align: right;
}

.input-form li div:last-child {
  text-align: left;
}

.content {
  width: 70%;
  min-width: 1200px;
  height: 85vh;
  background: white;
  margin: 15px auto 0;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.next-btn {
  margin-bottom: 30px;
  width: 350px;
  margin: 70px auto 0;
}

</style>
